import { gql } from '@apollo/client';
import { connectionTemplate } from '.';
import {
  ACCOMMODATION_FIELDS,
  KIOSK_OPTIONS_FIELDS,
  ROOM_FIELDS,
} from './fragment';

export const GET_USERS = gql`
  query getUsers($filter: UsersFilter) {
    getUsers(filter: $filter) {
      ${connectionTemplate`
        id
        identity
        name
        nickname
      `}
    }
  }
`;

export const GET_IS_ACTIVE_ACCOMMODATIONS = gql`
  query getIsActiveAccommodations {
    getIsActiveAccommodations {
      id
      name
    }
  }
`;

export const GET_ALL_ACCOMMODATIONS = gql`
  query getAccommodations {
    getAccommodations {
      edges {
        node {
          id
          name
          contact
          address1
          address2
          isActive
          isCmsActive
          csContact
          registererName
          registrationNumber
          isBookingEngineEnabled
          conciergePaymentConfig
          conciergeServiceSettings {
            enableConcierge
          }
          # isConciergeEnabled
          rooms {
            id
          }
        }
      }
    }
  }
`;

export const GET_ALL_ACCOMMODATION_KIOSKS = gql`
  query getAccommodations {
    getAccommodations {
      edges {
        node {
          id
          name
          address1
          kiosks {
            id
            name
            type
            state
            connectionState
            options {
              version
              ccuVersion
              useStandaloneCCU
              ccuType
              activateAdminStrategy
              useAgreementTerms
              useBlockMinor
              useBlockMinorInReservation
              blockMinorAgeBase
              cardType
            }
          }
        }
      }
    }
  }
`;

export const GET_MY_ACCOMMODATIONS = gql`
  ${ACCOMMODATION_FIELDS}
  ${KIOSK_OPTIONS_FIELDS}
  query getMyAccommodations {
    getMyAccommodations {
      ...AccommodationFields
      kioskArticle {
        id
        subject
        content
        attachments
        tag
      }
      kioskMainArticle {
        id
        subject
        content
        attachments
        tag
      }
      kiosks {
        id
        name
        type
        location
        options {
          ...KioskOptionsFields
        }
      }
    }
  }
`;

export const GET_SINGLE_ACCOMMODATION = gql`
  ${ACCOMMODATION_FIELDS}
  ${KIOSK_OPTIONS_FIELDS}
  query getSingleAccommodationOnConsole($accommodationId: ID!) {
    getSingleAccommodation(id: $accommodationId) {
      ...AccommodationFields
      kioskArticle {
        id
        subject
        content
        attachments
        tag
      }
      kioskMainArticle {
        id
        subject
        content
        attachments
        tag
      }
      termsArticle {
        id
        subject
        content
      }
      kiosks {
        id
        name
        type
        location
        connectionState
        options {
          ...KioskOptionsFields
        }
      }
    }
  }
`;
export const GET_ACCOMMODATION_PG_CONFIG = gql`
  query getAccommodationPgConfig($accommodationId: ID!) {
    getAccommodationPgConfig(accommodationId: $accommodationId) {
      accommodationId
      data
    }
  }
`;

export const GET_MY_ROOMS_IN_ACCOMMODATIONS = gql`
  ${ROOM_FIELDS}
  query getMyAccommodations {
    getMyAccommodations {
      id
      name
      options {
        key
        value
      }
      cardData
      kiosks {
        id
        rooms {
          id
          number
          order
        }
      }
      rooms {
        ...RoomFields
        roomType {
          id
          name
        }
      }
      roomTypes {
        id
        name
        rooms {
          ...RoomFields
        }
      }
    }
  }
`;

export const GET_ROOMS_IN_SINGLE_ACCOMMODATION = gql`
  ${ROOM_FIELDS}
  query getRoomsInSingleAccommodationOnConsole($accommodationId: ID!) {
    getSingleAccommodation(id: $accommodationId) {
      id
      name
      cardData
      cleanCardData
      options {
        key
        value
      }
      kiosks {
        id
        rooms {
          id
          number
          order
        }
      }
      rooms {
        ...RoomFields
        roomType {
          id
          name
        }
      }
      roomTypes {
        id
        name
        rooms {
          ...RoomFields
          roomDevices {
            id
            name
            type
          }
        }
        prices {
          id
          priceType
          defaultRentTime
          extendedRentTime
        }
      }
    }
  }
`;


export const GET_S3_OBJECT_LIST = gql`
  query getS3Objects($bucket: String!, $prefix: String, $delimiter: String) {
    getS3Objects(bucket: $bucket, prefix: $prefix, delimiter: $delimiter) {
      Contents {
        Key
        LastModified
        Size
      }
      IsTruncated
      Name
      Prefix
      Delimiter
      MaxKeys
      CommonPrefixes {
        Prefix
      }
      KeyCount
    }
  }
`;

export const GET_S3_OBJECT_SIGNED_URL = gql`
  query GET_S3_OBJECT_SIGNED_URL($bucket: String!, $key: String!) {
    getS3ObjectSignedUrl(bucket: $bucket, key: $key)
  }
`;
