import { Box, Button, Typography } from "@material-ui/core";
import type { Accommodation, Maybe } from "../../../typedefs/gql";

export const AccommodationItem = ({
	accommodation,
	selected,
	onClick,
}: {
	accommodation?: any;
	selected: boolean;
	onClick?: (id?: Maybe<string>) => void;
}) => {
	return (
		<Box
			padding={3}
			margin={1}
			width={300}
			display={"flex"}
			flexDirection={"row"}
			justifyContent={"space-between"}
			style={{
				background: selected ? "black" : "white",
				color: selected ? "white" : "black",
			}}
			onClick={() => {
				onClick?.(accommodation?.id);
			}}
		>
			<Typography variant="h5">{accommodation?.name}</Typography>
			<Button
				variant="outlined"
				color={selected ? "secondary" : "primary"}
				aria-selected={selected}
			>
				{selected ? "선택됨" : "선택"}
			</Button>
		</Box>
	);
};
