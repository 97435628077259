import { useQuery } from "@apollo/client";
import { Box, Button, Typography } from "@material-ui/core";
import { useMemo } from "react";
import { GET_S3_OBJECT_LIST } from "../../../graphql/query";
import type { Kiosk, Maybe } from "../../../typedefs/gql";

type Props = {
	selectedId: Maybe<string>;
	selectedKioskId?: Maybe<string>;
	kiosks?: Maybe<Kiosk>[];
	onKioskClick: (kioskId?: Maybe<string>) => void;
};

const KioskLists = ({
	selectedId,
	selectedKioskId,
	kiosks,
	onKioskClick,
}: Props) => {
	const { data } = useQuery(GET_S3_OBJECT_LIST, {
		variables: {
			bucket: "vendit-kiosk-logs",
			prefix: `${selectedId ?? ""}/`,
			delimiter: "/",
		},
		skip: !selectedId,
	});

	const logs = useMemo(() => data?.getS3Objects?.CommonPrefixes, [data]);
	console.log("🚀 ~ logs:", logs);
	// const [logs, setLogs] = useState<ListObjectsOutput>();

	// const listObjectsCommand = new ListObjectsCommand({
	//   Bucket: 'vendit-kiosk-logs',
	//   Delimiter: `/`,
	//   Prefix: `${selectedId}/`,
	// });

	return selectedId && logs ? (
		<>
			{logs?.map(({ Prefix }) => {
				const kioskId = Prefix?.split("/")[1];
				const kiosk = kiosks?.find((kiosk) => kiosk?.id === kioskId);
				const selected = selectedKioskId === kioskId;
				return (
					<Box
						padding={3}
						margin={1}
						width={300}
						key={Prefix}
						display={"flex"}
						flexDirection={"row"}
						justifyContent={"space-between"}
						style={{
							background: selected ? "black" : "white",
							color: selected ? "white" : "black",
						}}
						onClick={() => onKioskClick?.(kioskId)}
					>
						<Typography variant="h5">
							{kiosk
								? `${kiosk?.name} - ${kiosk?.connectionState} 
              버전명: ${kiosk?.options?.version}`
								: kioskId}
						</Typography>
						<Button
							variant="outlined"
							color={selected ? "secondary" : "primary"}
						>
							선택
						</Button>
					</Box>
				);
			})}
		</>
	) : null;
};

export default KioskLists;
