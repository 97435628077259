// @ts-nocheck
import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { FlexCol, FlexRow, WrapperContainer } from "../../components/Layout";
import Loading from "../../components/Loading";
import SessionOut from "../../components/SessionOut";
import {
	GET_S3_OBJECT_LIST,
	GET_SINGLE_ACCOMMODATION,
} from "../../graphql/query";
import type { Accommodation, Maybe } from "../../typedefs/gql";
import KioskLists from "../LogDownload/components/KioskLists";
import LogLists from "../LogDownload/components/LogLists";

type Props = {};

const AccommodationLog = (props: Props) => {
	const { id: accommodationId } = useParams();

	const {
		error,
		loading,
		data: myAccommodationsData,
	} = useQuery<{
		getSingleAccommodation: Accommodation;
	}>(GET_SINGLE_ACCOMMODATION, {
		variables: {
			accommodationId: accommodationId,
		},
	});

	const [selected, setSelected] = useState<Maybe<string>>(accommodationId);

	const [selectedKioskId, setSelectedKioskId] = useState<Maybe<string>>(null);

	const onKioskClick = (kioskId?: Maybe<string>) => {
		setSelectedKioskId(kioskId || null);
	};

	return (
		<WrapperContainer>
			{error ? (
				<SessionOut />
			) : loading ? (
				<Loading />
			) : (
				<FlexRow style={{ height: "inherit" }}>
					<FlexCol style={{ overflow: "scroll" }}>
						<KioskLists
							selectedId={selected}
							selectedKioskId={selectedKioskId}
							kiosks={myAccommodationsData?.getSingleAccommodation?.kiosks}
							onKioskClick={onKioskClick}
						/>
					</FlexCol>
					<FlexCol style={{ overflow: "scroll" }}>
						<LogLists
							bucketPrefix={
								selectedKioskId ? `${selected}/${selectedKioskId}` : undefined
							}
						/>
					</FlexCol>
				</FlexRow>
			)}
		</WrapperContainer>
	);
};

export default AccommodationLog;
