import { Box, Button, Typography } from "@material-ui/core";
import { GET_S3_OBJECT_LIST, GET_S3_OBJECT_SIGNED_URL } from "graphql/query";
import { useLazyQuery, useQuery } from "@apollo/client";
import { useMemo } from "react";
import { cloneDeep } from "@apollo/client/utilities";

type Props = {
	bucketPrefix?: string;
};
// https://vendit-kiosk-logs.s3.ap-northeast-2.amazonaws.com/019d1430-bbe1-11ec-b97e-fb75873f97c8/1a55d070-bbe1-11ec-b97e-fb75873f97c8/2022-08-26.log?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2HRKHNPWIIRXZGYX%2F20241119%2Fap-northeast-2%2Fs3%2Faws4_request&X-Amz-Date=20241119T073434Z&X-Amz-Expires=900&X-Amz-Signature=0e70131662db44e6ad95a4f27875ce530b7ff71e1bd0b9248ff1b921927fe2be&X-Amz-SignedHeaders=host"
export async function download(signedUrl: string): Promise<void> {
	try {
		// Fetch the file from the signed URL
		const response = await fetch(signedUrl);

		// Check if the response is OK
		if (!response.ok) {
			throw new Error(
				`Failed to download file. HTTP status: ${response.status}`,
			);
		}

		// Read the file as a Blob
		const blob = await response.blob();

		// Create a URL for the Blob
		const url = window.URL.createObjectURL(blob);

		const fileName = signedUrl.split("?")[0].split("/")[
			signedUrl.split("/").length - 1
		];

		// Create a temporary anchor element to trigger the download
		const anchor = document.createElement("a");
		anchor.href = url;
		anchor.download = fileName;
		anchor.style.display = "none";

		// Append the anchor to the body and trigger a click
		document.body.appendChild(anchor);
		anchor.click();

		// Clean up
		document.body.removeChild(anchor);
		window.URL.revokeObjectURL(url);

		console.log(`File downloaded successfully: ${fileName}`);
	} catch (error) {
		console.error(`Error downloading file: ${error?.message}`);
		throw error;
	}
}

const LogLists = ({ bucketPrefix }: Props) => {
	const { data } = useQuery(GET_S3_OBJECT_LIST, {
		variables: {
			bucket: "vendit-kiosk-logs",
			prefix: `${bucketPrefix ?? ""}`,
		},
		skip: !bucketPrefix,
	});
	console.log("🚀 ~ LogLists ~ data:", data);

	const [emitGetSignedUrl, { data: signedUrlData }] = useLazyQuery(
		GET_S3_OBJECT_SIGNED_URL,
		{
			onCompleted: (data) => {
				download(data.getS3ObjectSignedUrl);
			},
		},
	);

	const logs = useMemo(
		() => cloneDeep(data?.getS3Objects?.Contents || []),
		[data],
	);

	const handleDownload = async (key: string, fileName: string) => {
		try {
			emitGetSignedUrl({
				variables: {
					bucket: "vendit-kiosk-logs",
					key,
				},
			});
			return true;
		} catch (error) {
			console.log(error);
			return false;
		}
	};

	return logs ? (
		<>
			{logs
				?.sort((a, b) => {
					if ((a?.Key || "") < (b?.Key || "")) {
						return 1;
					}
					return -1;
				})
				.map((log) => {
					return (
						<Box
							padding={3}
							margin={1}
							width={300}
							key={log?.Key}
							display={"flex"}
							flexDirection={"row"}
							justifyContent={"space-between"}
							style={{
								background: "white",
							}}
						>
							<Typography variant="h5">
								{log.Key?.split("/")[log.Key?.split("/").length - 1]}
							</Typography>
							<Button
								variant="outlined"
								color={"primary"}
								onClick={() =>
									handleDownload(
										log.Key || "",
										log.Key?.split("/")[log.Key?.split("/").length - 1] || "",
									)
								}
							>
								다운로드
							</Button>
						</Box>
					);
				})}
		</>
	) : null;
};

export default LogLists;
