import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { FlexCol, FlexRow, WrapperContainer } from "../../components/Layout";
import Loading from "../../components/Loading";
import SessionOut from "../../components/SessionOut";
import {
	GET_ALL_ACCOMMODATION_KIOSKS,
	GET_S3_OBJECT_LIST,
} from "../../graphql/query";
import type { AccommodationConnection, Maybe } from "../../typedefs/gql";
import { AccommodationItem } from "./components/AccommodationItem";
import KioskLists from "./components/KioskLists";
import LogLists from "./components/LogLists";

const LogDownload = () => {
	const { loading, data, error, refetch } = useQuery<{
		getAccommodations: AccommodationConnection;
	}>(GET_ALL_ACCOMMODATION_KIOSKS);

	const { data: logsData } = useQuery(GET_S3_OBJECT_LIST, {
		variables: {
			bucket: "vendit-kiosk-logs",
			delimiter: "/",
		},
	});

	const [selected, setSelected] = useState<Maybe<string>>(null);

	const [selectedKioskId, setSelectedKioskId] = useState<Maybe<string>>(null);

	const onClick = (id?: Maybe<string>) => {
		setSelected(id || null);
	};
	const onKioskClick = (kioskId?: Maybe<string>) => {
		setSelectedKioskId(kioskId || null);
	};

	useEffect(() => {
		setSelectedKioskId(null);
	}, [selected]);

	return (
		<WrapperContainer>
			{error ? (
				<SessionOut />
			) : loading ? (
				<Loading />
			) : (
				<FlexRow style={{ height: "inherit" }}>
					<FlexCol style={{ overflow: "scroll" }}>
						{logsData?.getS3Objects?.CommonPrefixes?.map(({ Prefix }) => {
							const accommodationId = Prefix?.split("/")[0];
							const accommodation = data?.getAccommodations?.edges?.find(
								(acc) => acc?.node?.id === accommodationId,
							)?.node || {
								id: accommodationId,
								name: accommodationId,
							};
							return (
								<AccommodationItem
									accommodation={accommodation}
									selected={selected === accommodationId}
									key={accommodationId}
									onClick={onClick}
								/>
							);
						})}
					</FlexCol>
					<FlexCol style={{ overflow: "scroll" }}>
						<KioskLists
							selectedId={selected}
							selectedKioskId={selectedKioskId}
							kiosks={
								data?.getAccommodations?.edges?.find(
									(acc) => acc?.node?.id === selected,
								)?.node?.kiosks
							}
							onKioskClick={onKioskClick}
						/>
					</FlexCol>
					<FlexCol style={{ overflow: "scroll" }}>
						<LogLists
							bucketPrefix={
								selectedKioskId ? `${selected}/${selectedKioskId}` : undefined
							}
						/>
					</FlexCol>
				</FlexRow>
			)}
		</WrapperContainer>
	);
};

export default LogDownload;
